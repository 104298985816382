import $ from 'jquery';
import Swiper from 'swiper';
import '../../node_modules/malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.concat.min';
import '../../node_modules/jquery-mask-plugin/dist/jquery.mask.min';
import '../../node_modules/jquery-popup-overlay/jquery.popupoverlay';
import '../../node_modules/jquery-validation/dist/jquery.validate.min';

$(window).scroll(function() {
  if($(this).scrollTop() > 0) {
    $('.header').addClass('scroll');
  }
  else {
    $('.header').removeClass('scroll');
  }
});

$(window).on('load',function() {
  $('.reviews__slider_text').mCustomScrollbar({
  });$('.reviews-content-text').mCustomScrollbar({
  });
  $('.standard__info-border_block p').mCustomScrollbar({
  });
});

var swiper = new Swiper('.reviews__slider .swiper-container', {
  slidesPerView: 3,
  spaceBetween: 10,
  loop: true,
  loopFillGroupWithBlank: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  breakpoints: {
    991: {
      slidesPerView: 2,
      spaceBetween: 40,
    },
    768: {
      slidesPerView: 1,
      spaceBetween: 30,
    }
  }
});

var tesSwiper = new Swiper('.tes-slider .strong-content', {
  slidesPerView: 3,
  spaceBetween: 10,
  loop: true,
  loopFillGroupWithBlank: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  breakpoints: {
    991: {
      slidesPerView: 2,
      spaceBetween: 40,
    },
    768: {
      slidesPerView: 1,
      spaceBetween: 30,
    }
  }
});

$(document).ready(function() {
  // - - - - FAQ-BURGER - - - -
  $('.faq__head').click(function() {
    if ($(this).hasClass('active')) {
      $(this).removeClass('active');
    }
    else {
      $('.faq__head').removeClass('active');
      $(this).addClass('active');
    }
    $('.faq__toggle').addClass('active');
    $(this).next().slideToggle();
    $('.faq__toggle').not($(this).next()).slideUp();
  });
  // - - - - NUMBER - BEFORE - - - -
  var num = Number($('.what-need__block').length), i, str, elem;
  for (i = 1; i <= num; i++) {
    str = i;
    if (str < 10) {
      elem = '0'+i;
    } else {
      elem = i;
    }
    document.styleSheets[0].addRule('.what-need__block:nth-of-type('+i+')::before','content: "'+elem+'";');
  }
  // - - - - HAMBURGER - - - -
  $('.nav__button').click(function() {
    $('.header').toggleClass('active');
    $('.nav__button').toggleClass('active');
    $('.nav').toggleClass('active');
  });




  $('.modal-callback').popup({
    transition: 'all 0.3s',
    outline: true,
    focusdelay: 400,
    vertical: 'top',
    closebutton: true
  });

  $('input[type="tel"]').mask('+7 (000) 000-00-00');
  $.validator.addMethod('phoneno', function(phoneNumber, element) {
    return this.optional(element) || phoneNumber.match(/\+[0-9]{1}\s\([0-9]{3}\)\s[0-9]{3}-[0-9]{2}-[0-9]{2}/);
  }, 'Введите Ваш телефон');

  $('.form').each(function(index, el) {
    $(el).addClass('form-' + index);

    $('.form-' + index).validate({
      rules: {
        name: 'required',
        agree: 'required',
        formName: 'required',
        tel: 'required',
      },
      messages: {
        name: 'Введите Ваше имя',
        tel: 'Введите Ваш телефон',
        agree: 'Нужно соглашение на обработку данных',
      },
      submitHandler: function(form) {
        var t = $('.form-' + index).serialize();
        ajaxSend('.form-' + index, t);
      }
    });
  });

  function ajaxSend(formName, data) {
    jQuery.ajax({
      type: 'POST',
      url: 'sendmail.php',
      data: data,
      success: function() {
        $('.modal').popup('hide');
        $('#thanks').popup('show');
        setTimeout(function() {
          $(formName).trigger('reset');
        }, 2000);
      }
    });
  };
  var mapControl = document.getElementById('map') !== null ? initMap() : null;
});
